import { IUserSessionComponentProps, UserSessionViewModelKey } from '@AppModels/AppState';
import { EventLogger } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { Dropdown } from '@WebComponents/Dropdown';
import { LoadingSpinner } from '@WebComponents/LoadingSpinner';
import { EditEmailSignature, EditEmailSignatureModal } from '@WebComponents/email/EditEmailSignature';
import { DisclosureIcon } from '@WebComponents/svgs/icons/DisclosureIcon';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { baseStyleSheet } from '../../../styles/styles';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

interface IStaticToolbarSignatureButtonComponentProps
	extends IUserSessionComponentProps,
		IImpersonationContextComponentProps {
	className?: string;
	disabled?: boolean;
	forUser?: Api.IUser;
	hideAddNew?: boolean;
	hideEditing?: boolean;
	initialSignatureTemplate?: Api.ITemplate | null | undefined;
	onEmailSignatureSaved?(emailSignature: Api.ITemplate): void;
	onEmailSignatureSelected?(emailSignature: Api.ITemplate): void;
	shouldEditInline?: boolean;
	templates?: Api.TemplatesViewModel;
	selectedEmailSignature?: Api.ITemplate;
	emailSignatures?: Api.ITemplate[];
}

interface IState {
	disabled?: boolean;
	editingEmailSignature?: Api.ITemplate;
	emailSignatures?: Api.ITemplate[];
	isOpen?: boolean;
	selectedEmailSignature?: Api.ITemplate;
	templates?: Api.TemplatesViewModel;
}

class _SignatureToolbarItem extends React.Component<IStaticToolbarSignatureButtonComponentProps, IState> {
	public static getDerivedStateFromProps(props: IStaticToolbarSignatureButtonComponentProps, state: IState) {
		const nextState: IState = {};

		if (props.disabled !== state.disabled) {
			nextState.disabled = props.disabled;
		}

		if (props.emailSignatures !== state.emailSignatures) {
			nextState.emailSignatures = props.emailSignatures;
		}

		if (props.templates !== state.templates) {
			nextState.templates = props.templates;
		}

		if (props.selectedEmailSignature !== state.selectedEmailSignature) {
			nextState.selectedEmailSignature = props.selectedEmailSignature;
		}

		return Object.keys(nextState).length > 0 ? nextState : null;
	}

	constructor(props: Readonly<IStaticToolbarSignatureButtonComponentProps>) {
		super(props);
		this.state = {
			selectedEmailSignature: props.initialSignatureTemplate,
			templates: props.templates,
			emailSignatures: props.emailSignatures,
			disabled: props.disabled,
		};
	}

	public render() {
		const { className, hideAddNew, hideEditing, shouldEditInline, forUser } = this.props;
		const { isOpen, emailSignatures, selectedEmailSignature, editingEmailSignature, templates, disabled } = this.state;
		const anchor = (
			<button className={css(styleSheet.anchor)} disabled={disabled} onClick={this.onClick}>
				<span className={`${css(styleSheet.anchorText)} truncate-text`}>
					{selectedEmailSignature && selectedEmailSignature?.id !== Api.EmptyEmailSignatureTemplateId
						? this.getEmailSignatureName(selectedEmailSignature)
						: this.getEmptyStateSignatureName()}
				</span>
				<DisclosureIcon className={css(styleSheet.anchorDisclosureIcon)} />
			</button>
		);

		return (
			<div className={`${css(styleSheet.container)} signature-toolbar-item ${className || ''}`}>
				{shouldEditInline && emailSignatures?.length === 0 ? (
					this.renderEmptyState()
				) : (
					<div className={css(styleSheet.dropdownContainer, shouldEditInline && styleSheet.dropdownContainerNarrow)}>
						<div className={css(styleSheet.dropdownContainer)}>
							{shouldEditInline && <span className={css(styleSheet.dropdownTitle)}>{'Signature: '}</span>}

							<Dropdown
								anchor={anchor}
								className={shouldEditInline && css(styleSheet.dropdownInline)}
								contentClassName={css(styleSheet.dropdownMenu, !!shouldEditInline && styleSheet.dropdownMenuInline)}
								contentPositionY={!shouldEditInline ? 'top' : 'bottom'}
								isOpen={isOpen}
								onOpenChanged={this.onOpenChanged}
								openOnClick={false}
							>
								<div
									className={css(
										styleSheet.dropdownMenuContent,
										shouldEditInline && styleSheet.dropdownMenuContentScrollable
									)}
								>
									{templates.signatureTemplates?.isFetching ? (
										<LoadingSpinner className={css(styleSheet.dropdownMenuContentItem)} type='small' />
									) : (
										<React.Fragment>
											{!shouldEditInline && (
												<div
													className={css(styleSheet.dropdownMenuContentItem)}
													onClick={this.onEmailSignatureSelected({ id: Api.EmptyEmailSignatureTemplateId })}
												>
													{this.getEmptyStateSignatureName()}
												</div>
											)}
											{(emailSignatures || []).map((x, i) => {
												const name = this.getEmailSignatureName(x);
												return (
													<div
														className={css(styleSheet.dropdownMenuContentItem)}
														key={i}
														onClick={this.onEmailSignatureSelected(x)}
													>
														<span
															className={`${css(styleSheet.dropdownMenuContentItemName)} truncate-text`}
															title={name}
														>
															{name}
														</span>
														{!shouldEditInline && !hideEditing && (
															<button
																className={css(styleSheet.dropdownMenuContentItemEditButton)}
																onClick={this.onEditEmailSignature(x)}
															>
																Edit
															</button>
														)}
													</div>
												);
											})}
											{!hideAddNew && (
												<div
													className={css(
														styleSheet.dropdownMenuContentItem,
														styleSheet.dropdownMenuContentItemAddSignatureButton
													)}
													onClick={this.onEditEmailSignature(null)}
												>
													Add signature
												</div>
											)}
										</React.Fragment>
									)}
								</div>
							</Dropdown>
						</div>
						{shouldEditInline && (
							<button
								className={css(baseStyleSheet.brandLink, styleSheet.addNewButton)}
								onClick={this.onEditEmailSignature(null)}
							>
								Add New Signature
							</button>
						)}
					</div>
				)}
				{shouldEditInline ? (
					<EditEmailSignature
						emailSignature={selectedEmailSignature}
						forUser={forUser}
						hideHeader={true}
						onCancel={this.onCancel}
						onEmailSignatureDeleted={this.onEmailSignatureDeleted}
						onEmailSignatureEditClick={this.editEmailSignature}
						onEmailSignatureSaved={this.onEmailSignatureSaved}
						shouldShowDeleteConfirmation={true}
						templates={templates}
					/>
				) : null}
				<EditEmailSignatureModal
					emailSignature={editingEmailSignature}
					forUser={forUser}
					onEmailSignatureDeleted={this.onEmailSignatureDeleted}
					templates={templates}
					modalProps={{
						isOpen: !!editingEmailSignature,
						onRequestClose: this.onEditEmailSignatureRequestClose,
					}}
				/>
			</div>
		);
	}

	private renderEmptyState() {
		return (
			<div className={css(styleSheet.emptyContainer)}>
				<SvgIcon height={120} width={120}>
					<g fill='none' fillRule='evenodd'>
						<circle cx='60' cy='60' r='60' fill='#E8F3F9' />
						<g fill='none'>
							<path fill='#F9F9F9' d='M18.186 27.265l65.468-8.563 8.567 63.97-65.468 8.563-8.567-63.97z' />
							<path
								fill='#E0E0E0'
								d='M22.918 37.822l24.359-3.436.213 1.73-24.36 3.436-.212-1.73zM24.327 40.707l39.53-5.14.223 1.664-39.53 5.14-.223-1.664zM23.434 43.008l37.31-4.76.23 1.682-37.31 4.759-.23-1.681zM26.025 50.97l24.106-2.606.252 1.603-24.106 2.607-.252-1.603zM26.327 54.707l39.53-5.14.223 1.664-39.53 5.14-.223-1.664zM26.412 57.154l36.373-5.045.21 1.675-36.373 5.045-.21-1.675z'
							/>
							<path fill='#CAE0EC' d='M27.083 66.32l55.733-7.129 2.508 18.427-55.732 7.129-2.51-18.427z' />
							<path
								fill='#046CB6'
								fillRule='evenodd'
								d='M72.08 68.737c-1.67-1.105-3.579-1.576-4.83.423-.093.15-.184.322-.279.501-.332.627-.708 1.338-1.387 1.451-.999.168-1.828-.713-2.538-1.467-.204-.218-.4-.424-.585-.592-1.547-1.398-3.576-1.389-5.165.069-.805.738-1.349 1.705-1.843 2.676-.065.127-.126.266-.188.408-.145.327-.297.67-.513.925-.574.677-1.592.315-2.293.067l-.06-.022c-.143-.043-.283-.104-.424-.164l-.04-.017a4.675 4.675 0 001.643-2.529c.452-1.642-.023-3.734-1.864-4.079-1.647-.316-3.259.94-3.575 2.612-.268 1.421.27 2.75 1.176 3.742-.27.036-.543.06-.815.083-.352.03-.701.06-1.035.118a7.11 7.11 0 00-.821.19c-1.315-1.25-3.309-2.02-4.947-1.655-.793.177-.648 1.467.15 1.288 1.247-.278 2.373.232 3.367.955l.083.063c-.155.112-.3.238-.432.377-1.015 1.073-.999 3.265.486 3.87 2.095.849 3.099-2.073 2.289-3.606a3.175 3.175 0 00-.108-.191c.344-.059.697-.09 1.04-.12.212-.018.42-.036.621-.06a6.332 6.332 0 001.345-.31l.076.047c1.526.89 4.056 1.795 5.28-.092.206-.32.418-.722.645-1.153.937-1.778 2.135-4.05 4.248-3.094.61.275 1.081.772 1.551 1.269.356.375.71.75 1.124 1.028.86.584 1.954.931 2.954.444.739-.36 1.148-1.069 1.548-1.762l.141-.244.048-.083c.22-.377.468-.804.866-.992.745-.351 1.61.222 2.22.627l.065.043c.671.442 1.456-.596.776-1.044zm-25.868 8.026c-.512.273-.833-.165-.917-.64-.102-.577.158-1.036.617-1.376.075-.056.153-.107.233-.154.621.743 1.034 1.663.074 2.173l-.007-.003zm6.36-7.67c-.149-1.088-1.133-1.556-2.085-1.088l.008-.002c-1.26.62-1.217 2.245-.586 3.31.25.407.579.758.965 1.036.128-.066.25-.141.366-.227.875-.652 1.48-1.94 1.332-3.028z'
								clipRule='evenodd'
							/>
						</g>
					</g>
				</SvgIcon>
				<button
					className={css(baseStyleSheet.brandLink, styleSheet.emptyLink)}
					onClick={this.onEditEmailSignature(null)}
				>
					Add Signature
				</button>
			</div>
		);
	}

	private getEmptyStateSignatureName() {
		const { shouldEditInline } = this.props;
		return shouldEditInline ? 'Select a Signature' : 'No Signature';
	}

	private onEmailSignatureSaved = (emailSignature: Api.ITemplate) => {
		const { onEmailSignatureSaved } = this.props;
		this.onEditEmailSignatureRequestClose(emailSignature, false);
		onEmailSignatureSaved?.(emailSignature);
	};

	private onEmailSignatureDeleted = (emailTemplate: Api.ITemplate) => {
		const { selectedEmailSignature, emailSignatures } = this.state;
		if (!!selectedEmailSignature && emailTemplate.id === selectedEmailSignature.id) {
			// removed selected template... update
			this.onEmailSignatureSelected(null)();
		}
		// update the list
		if (emailSignatures) {
			this.setState({
				emailSignatures: emailSignatures.filter(x => x.id !== emailTemplate.id),
			});
		}
	};

	private onCancel = () => {
		this.setState({ selectedEmailSignature: null });
	};

	private onEditEmailSignatureRequestClose = (editedEmailTemplate?: Api.ITemplate, cancel?: boolean) => {
		const nextState: IState = {
			editingEmailSignature: null,
		};

		if (!!editedEmailTemplate && !cancel) {
			const emailSignatures = [...this.state.emailSignatures];
			const indexOfMatch = emailSignatures.findIndex(x => x.id === editedEmailTemplate.id);
			if (indexOfMatch >= 0) {
				// replace with updated signature
				emailSignatures[indexOfMatch] = editedEmailTemplate;

				// updated selected, if needed
				if (!!this.state.selectedEmailSignature && this.state.selectedEmailSignature.id === editedEmailTemplate.id) {
					nextState.selectedEmailSignature = editedEmailTemplate;
				}
			} else {
				// add new signature
				emailSignatures.push(editedEmailTemplate);

				// set as selected
				nextState.selectedEmailSignature = editedEmailTemplate;
			}

			// update collection
			nextState.emailSignatures = emailSignatures;

			// update consumers
			this.onEmailSignatureSelected(editedEmailTemplate)();
		}
		this.setState(nextState);
	};

	private editEmailSignature = (emailSignature: Api.ITemplate) => {
		const nextState: IState = {
			editingEmailSignature: emailSignature || {},
			isOpen: false,
		};
		this.setState(nextState);

		const create = !nextState.editingEmailSignature.id;
		EventLogger.logInput('SignatureButton', `${create ? 'Create' : 'Edit'}Signature`, 'Click', {
			id: nextState.editingEmailSignature.id,
		});
	};

	private onEditEmailSignature = (emailSignature: Api.ITemplate) => (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();
		this.editEmailSignature(emailSignature);
	};

	private onEmailSignatureSelected = (emailSignature?: Api.ITemplate) => (e?: React.MouseEvent<HTMLElement>) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		const nextState: IState = {
			isOpen: false,
			selectedEmailSignature: emailSignature,
		};
		this.setState(nextState);
		this.props.onEmailSignatureSelected?.(emailSignature);

		EventLogger.logInput('SignatureButton', `Signature${emailSignature ? 'Selected' : 'Cleared'}`, 'Click', {
			id: emailSignature ? emailSignature.id : null,
		});
	};

	private getEmailSignatureName(emailSignature: Api.ITemplate) {
		const { emailSignatures } = this.state;

		if (!emailSignatures) {
			return emailSignature?.name || '';
		}

		if (!emailSignatures?.length) {
			return emailSignature.name || '';
		}

		return emailSignature.name || `Signature #${emailSignatures.indexOf(emailSignature) + 1}`;
	}

	private onOpenChanged = (isOpen: boolean) => {
		this.setState({
			isOpen,
		});
	};

	private onClick = (e: React.MouseEvent<HTMLElement>) => {
		this.stopMouseEvent(e);
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	private stopMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
	};
}

export const SignatureToolbarItem = inject(UserSessionViewModelKey, ImpersonationContextKey)(_SignatureToolbarItem);
