import { StyleSheet } from 'aphrodite';
import { brandSecondary } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	automationTemplateSelectorAutomationIcon: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 15,
		display: 'flex',
		flexShrink: 0,
		height: 24,
		justifyContent: 'center',
		width: 24,
		...CssSelectors.allChildren(
			{
				height: 12,
				width: 12,
			},
			'.svg-icon'
		),
	},
	automationTemplateSelectorItem: {
		alignItems: 'center',
		display: 'flex',
	},
});
