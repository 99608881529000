import { css } from 'aphrodite';
import * as React from 'react';
import { ITemplate } from '../../../../../extViewmodels';
import { brandPrimary } from '../../../../styles/colors';
import { bs } from '../../../../styles/styles';
import { DocumentSearchIcon } from '../../../svgs/icons/DocumentSearchIcon';
import { EmailTemplateBrowserDialog } from '../../guide/EmailTemplateBrowserDialog';
import { s } from './styles';

interface Props {
	onTemplateSelected: (template: ITemplate) => void;
}

export function EmailMessageTemplateSelectorButton(props: Props) {
	const [showingMessageSelectorModal, setShowingMessageSelectorModal] = React.useState(false);
	const onMessageSelectorModalRequestClose = (template: ITemplate, cancel?: boolean) => {
		setShowingMessageSelectorModal(false);
		if (!!template && !cancel) {
			props.onTemplateSelected(template);
		}
	};
	return (
		<div className={css(s.container)}>
			<button className={css(s.button)} onClick={() => setShowingMessageSelectorModal(true)}>
				<DocumentSearchIcon width={16} height={16} color={brandPrimary} />
				<div className={css(bs.truncateText)}>Use existing email template</div>
			</button>
			<EmailTemplateBrowserDialog
				modalProps={{
					isOpen: showingMessageSelectorModal,
					onRequestClose: onMessageSelectorModalRequestClose,
					useDefaultHeader: true,
				}}
				title='Use Existing Email Template'
			/>
		</div>
	);
}
