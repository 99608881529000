import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, titles } from '../../../../styles/colors';
import { CssSelectors } from '../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		display: `flex`,
		flexDirection: `column`,
		height: `calc(80vh - 76px)`,
	},
	header: {
		color: titles,
		fontSize: 18,
		textAlign: 'center',
		marginBottom: 16,
	},
	modal: {
		':nth-child(1n) > .modal-overlay': {
			':nth-child(1n) > .modal-overlay-content': {
				height: '80vh',
				width: 640,
			},
		},
	},
	cardContainer: {
		height: 'calc(100% - 114px)',
	},
	searchContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 8,
		...CssSelectors.allChildren(
			{
				width: '100%',
			},
			'div'
		),
	},
	templateCard: {
		color: '#3F3F3F',
		paddingBottom: 20,
		width: '100%',
		...CssSelectors.allChildren(
			{
				color: brandPrimaryText,
			},
			'.campaign-template-card-name'
		),
	},
	templateCardContainer: {
		width: '100%',
	},
	cardsContainer: {
		display: `flex`,
		flexDirection: `column`,
		gap: 8,
		overflow: `auto`,
	},
	trigger: {
		height: '40px',
		padding: 0,
		paddingRight: 15,
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
	select: {
		maxWidth: '100%',
		width: 'auto',
		marginBottom: 8,
	},
});
