import { StyleDeclarationValue, css } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { toggleOff, toggleOn } from '../../styles/colors';
import './styles.less';

export interface IToggleProps {
	checkedColor?: string;
	className?: string;
	disabled?: boolean;
	id: string;
	isOn?: boolean;
	onToggleCheckChanged?(checked: boolean): void;
	square?: boolean;
	text?: string;
	textStyles?: StyleDeclarationValue[];
	uncheckedColor?: string;
}

export const Toggle: React.FC<IToggleProps> = ({
	checkedColor = toggleOn,
	className = '',
	disabled = false,
	id,
	isOn: controlledIsOn,
	onToggleCheckChanged,
	square = false,
	text,
	textStyles = [],
	uncheckedColor = toggleOff,
}) => {
	const [internalIsOn, setInternalIsOn] = useState(controlledIsOn);
	const isChecked = onToggleCheckChanged ? controlledIsOn : internalIsOn;

	useEffect(() => {
		if (internalIsOn !== controlledIsOn) {
			setInternalIsOn(controlledIsOn);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [controlledIsOn]);

	const setIsOn = (newIsOn: boolean) => {
		if (onToggleCheckChanged) {
			onToggleCheckChanged(newIsOn);
		} else {
			setInternalIsOn(newIsOn);
		}
	};

	const toggle = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsOn(!isChecked);
	};

	const onCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsOn(e.target.checked);
	};

	return (
		<label
			className={`toggle ${square ? '' : 'round'} ${className}`}
			htmlFor={id}
			onClick={!disabled ? toggle : undefined}
		>
			<input
				className='toggle-input'
				disabled={disabled}
				id={id}
				type='checkbox'
				onChange={onCheckChanged}
				checked={isChecked || false}
				role='toggle'
			/>
			<div
				className={`toggle-track ${disabled ? 'toggle-disabled' : ''}`}
				style={{ backgroundColor: isChecked ? checkedColor : uncheckedColor }}
			>
				<div className='toggle-thumb' />
			</div>
			{text && <span className={`toggle-text ${css(...textStyles)}`}>{text}</span>}
		</label>
	);
};
