import * as SelectPrimitive from '@radix-ui/react-select';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { ChevronDown } from '../svgs/icons/ChevronDown';
import { ChevronUp } from '../svgs/icons/ChevronUp';
import { s } from './styles';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Trigger>,
	Exclude<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>, 'className'> & {
		styles?: StyleDeclarationValue;
	}
>(({ styles, children, ...props }, ref) => (
	<SelectPrimitive.Trigger ref={ref} className={css(s.trigger, styles)} {...props}>
		{children}
		<SelectPrimitive.Icon asChild>
			<ChevronDown className={css(s.triggerChevron)} />
		</SelectPrimitive.Icon>
	</SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
	Exclude<React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>, 'className'> & {
		styles?: StyleDeclarationValue;
	}
>(({ styles, ...props }, ref) => (
	<SelectPrimitive.ScrollUpButton ref={ref} className={css(s.scrollButton, styles)} {...props}>
		<ChevronUp className={css(s.scrollButtonChevron)} />
	</SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
	Exclude<React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>, 'className'> & {
		styles?: StyleDeclarationValue;
	}
>(({ styles, ...props }, ref) => (
	<SelectPrimitive.ScrollDownButton ref={ref} className={css(s.scrollButton, styles)} {...props}>
		<ChevronDown className={css(s.scrollButtonChevron)} />
	</SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Content>,
	Exclude<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>, 'className'> & {
		styles?: StyleDeclarationValue;
	}
>(({ styles, children, position = 'popper', ...props }, ref) => (
	<SelectPrimitive.Portal>
		<SelectPrimitive.Content
			ref={ref}
			className={css(s.selectContent, position === 'popper' && s.selectContentPositionPopper, styles)}
			position={position}
			{...props}
		>
			<SelectScrollUpButton />

			<SelectPrimitive.Viewport className={css(s.selectViewport, position === 'popper' && s.selectViewportPopper)}>
				{children}
			</SelectPrimitive.Viewport>
			<SelectScrollDownButton />
		</SelectPrimitive.Content>
	</SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Label>,
	Exclude<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>, 'className'> & {
		styles?: StyleDeclarationValue;
	}
>(({ styles, ...props }, ref) => <SelectPrimitive.Label ref={ref} className={css(s.selectLabel, styles)} {...props} />);
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Item>,
	Exclude<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>, 'className'> & { styles?: StyleDeclarationValue }
>(({ styles, children, ...props }, ref) => (
	<SelectPrimitive.Item ref={ref} className={css(s.selectItem, styles)} {...props}>
		<SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
	</SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Separator>,
	Exclude<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>, 'className'> & {
		styles?: StyleDeclarationValue;
	}
>(({ styles, ...props }, ref) => (
	<SelectPrimitive.Separator ref={ref} className={css(s.selectSeparator, styles)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectScrollDownButton,
	SelectScrollUpButton,
	SelectSeparator,
	SelectTrigger,
	SelectValue,
};
