import { AutomationTemplateViewModel, EmailMessageViewModel, ITemplate } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../Select';
import { AutomationsIcon } from '../../svgs/icons/AutomationsIcon';
import { styleSheet } from './styles';

interface IProps {
	alternateKeyDateTemplates?: ITemplate[];
	automationTemplates?: AutomationTemplateViewModel[];
	emailMessage?: EmailMessageViewModel<File>;
	initialEmailBodyTemplate?: ITemplate;
	onSetTemplate?(template?: ITemplate | AutomationTemplateViewModel): void;
	selectedTemplate?: ITemplate | AutomationTemplateViewModel;
}

const AlternateKeyDatesHeaderItem: React.FC<{
	template?: ITemplate | AutomationTemplateViewModel;
	emailMessage?: EmailMessageViewModel<File>;
}> = observer(({ template, emailMessage }) => {
	return template instanceof AutomationTemplateViewModel ? (
		<div
			className={css(
				baseStyleSheet.truncateText,
				baseStyleSheet.horizontalStack,
				styleSheet.automationTemplateSelectorItem
			)}
		>
			<div className={css(styleSheet.automationTemplateSelectorAutomationIcon)}>
				<AutomationsIcon fillColor='#fff' />
			</div>
			<div className={css(baseStyleSheet.truncateText)}>{template.name}</div>
		</div>
	) : (
		<div className={css(baseStyleSheet.truncateText)}>{template?.name || emailMessage?.subject}</div>
	);
});

export const AlternateKeyDatesHeader: React.FC<IProps> = observer(props => {
	const {
		alternateKeyDateTemplates = [],
		automationTemplates = [],
		emailMessage,
		initialEmailBodyTemplate,
		onSetTemplate,
		selectedTemplate,
	} = props;

	const templateChoices = [...automationTemplates, ...alternateKeyDateTemplates, initialEmailBodyTemplate].filter(
		x => !!x
	);

	return (
		<Select
			value={selectedTemplate?.id}
			onValueChange={templateId => {
				const template = templateChoices?.find(t => templateId === t.id);
				if (selectedTemplate?.id !== template.id) {
					onSetTemplate(template);
				}
			}}
		>
			<SelectTrigger>
				<SelectValue asChild>
					<AlternateKeyDatesHeaderItem template={selectedTemplate} emailMessage={emailMessage} />
				</SelectValue>
			</SelectTrigger>
			<SelectContent>
				{templateChoices?.map(x => {
					return (
						<SelectItem value={x.id} key={`alternateKeyDatesHeader-${x.id}`}>
							<AlternateKeyDatesHeaderItem template={x} />
						</SelectItem>
					);
				})}
			</SelectContent>
		</Select>
	);
});
