import {
	DefaultModalName,
	ILocationState,
	IModalContext,
	ModalChildComponentContextKey,
	SizeConstraint,
} from '@AppModels/.';
import {
	FullScreenModalViewModelKey,
	IFullscreenModalComponentProps,
	IQuickAddEntityComponentProps,
	QuickAddEntityViewModelKey,
} from '@AppModels/AppState';
import { EventLogger } from '@AppModels/Logging';
import { getCompanyInitials, getPrincipalInitials, openUrlInNewTab } from '@AppModels/UiUtils';
import { IEntity, getTypeForEntity } from '@ViewModels';
import { Chip, IChipCallbacks } from '@WebComponents/chips/Chip';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CompanyViewModel, ContactViewModel, EntityViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { Avatar2 } from '../../Avatar2';
import ChipRemoveIconUrl from '../chipRemove.svg';
import { styleSheet } from './styles';

interface IProps
	extends IChipCallbacks,
		React.AllHTMLAttributes<HTMLDivElement>,
		IQuickAddEntityComponentProps,
		IFullscreenModalComponentProps,
		IModalContext {
	className?: string;
	entity: EntityViewModel<IEntity>;
	sizeConstraint?: SizeConstraint;
}

class _EntityChip extends React.Component<IProps> {
	public render() {
		const {
			className = '',
			entity,
			readOnly,
			quickAddEntity,
			sizeConstraint,
			fullscreenModal,
			parentModal,
			...restProps
		} = this.props;

		let imgSrc;
		let fallbackText = '';
		if (getTypeForEntity(entity.toJs()) === 'company') {
			const company = entity as CompanyViewModel;
			fallbackText = getCompanyInitials(company);
			imgSrc = company?.logoUrl;
		} else {
			const user = entity as ContactViewModel;
			fallbackText = getPrincipalInitials(user);
			imgSrc = user?.profilePic;
		}
		return (
			<Chip
				{...restProps}
				className={`${css(styleSheet.chip, sizeConstraint === 'compact' && styleSheet.compactChip)} ${className}`}
				onClick={this.onClick}
				removeIcon={!readOnly && <img src={ChipRemoveIconUrl} />}
				thumbnail={<Avatar2 fallbackText={fallbackText} imgSrc={imgSrc} smallText={sizeConstraint === 'compact'} />}
			>
				<div className={css(baseStyleSheet.truncateText)}>{entity.name}</div>
			</Chip>
		);
	}

	@computed
	private get entityLocation() {
		const { entity, fullscreenModal, parentModal } = this.props;
		if (!!parentModal && parentModal.name === DefaultModalName) {
			// chip lives in a default modal, not fullscreen, so just bypass pushing to fullscreen
			return null;
		}
		if (!!entity && !!fullscreenModal) {
			const locationState: ILocationState<EntityViewModel, IEntity> = {
				viewModel: entity,
			};
			return {
				pathname: `/${entity instanceof CompanyViewModel ? 'companies' : 'people'}/${entity.id}`,
				state: locationState,
			};
		}
		return null;
	}

	private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const { entity, onClick, quickAddEntity, fullscreenModal } = this.props;
		if (onClick) {
			onClick(e);
		}

		if (!e.defaultPrevented) {
			// only redirect if the event was not prevented
			e.stopPropagation();
			const isCompany = entity instanceof CompanyViewModel;

			if (!entity.isValid) {
				// show interface to create the new entity
				EventLogger.logInput('EntityChip', `Add${isCompany ? 'Company' : 'Contact'}`, 'Click');

				quickAddEntity.show({
					entity: entity.toJs(),
					onComplete: (error, savedEntity) => {
						if (!error && !!savedEntity) {
							// update the vm
							entity.setEntity(savedEntity);
						}
					},
					type: isCompany ? 'company' : 'contact',
				});
				return;
			}

			e.preventDefault();

			const entityLocation = this.entityLocation;
			if (entityLocation) {
				fullscreenModal.contextAwarePushLocation(entityLocation);
			} else {
				// create a link and open in a new window
				openUrlInNewTab(
					`${window.location.origin}/#/${getTypeForEntity(entity.toJs()) === 'company' ? 'companies' : 'people'}/${
						entity.id
					}`
				);
			}
		}
	};
}

const EntityChipAsObserver = observer(_EntityChip);
export const EntityChip = inject(
	QuickAddEntityViewModelKey,
	FullScreenModalViewModelKey,
	ModalChildComponentContextKey
)(EntityChipAsObserver);
