import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimaryHover, darkGrayFontColor, white } from '../../styles/colors';

const slideInFromTopNFadeNZoom = (endOffset = 0) => ({
	'0%': {
		transform: 'translateY(-8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: `translateY(${endOffset}px) scale(1)`,
		opacity: 1,
	},
});
const slideInFromBottomNFadeNZoom = (endOffset = 0) => ({
	'0%': {
		transform: 'translateY(8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: `translateY(${endOffset}px) scale(1)`,
		opacity: 1,
	},
});
const slideInFromLeftNFadeNZoom = (endOffset = 0) => ({
	'0%': {
		transform: 'translateX(-8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: `translateX(${endOffset}px) scale(1)`,
		opacity: 1,
	},
});
const slideInFromRightNFadeNZoom = (endOffset = 0) => ({
	'0%': {
		transform: 'translateX(8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: `translateX(${endOffset}px) scale(1)`,
		opacity: 1,
	},
});
const fadeOutZoomOut = {
	'0%': {
		transform: 'scale(1)',
		opacity: 1,
	},
	'100%': {
		transform: 'scale(0.95)',
		opacity: 0,
	},
};
export const s = StyleSheet.create({
	trigger: {
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 36,
		width: '100%',
		whitespace: 'nowrap',
		borderRadius: 6,
		border: `1px solid ${borderColor}`,
		backgroundColor: 'transparent',
		padding: `8px 12px`,
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
		color: darkGrayFontColor,
		':placeholder': {
			color: '#64748b',
		},
		':focus': {
			outline: 'none',
			boxShadow: '0 0 0 1px #94a3b8',
		},
		':disabled': {
			cursor: 'not-allowed',
			opacity: 0.5,
		},
		':has(*) > span': {
			overflow: 'hidden',
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': 1,
		},
	},
	triggerChevron: {
		display: 'block',
		boxSizing: 'border-box',
		height: 16,
		width: 16,
		opacity: 0.5,
	},
	scrollButton: {
		boxSizing: 'border-box',
		display: 'flex',
		cursor: 'default',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: 4,
		paddingBottom: 4,
	},
	scrollButtonChevron: {
		display: 'block',
		boxSizing: 'border-box',
		height: 16,
		width: 16,
	},
	selectContent: {
		boxSizing: 'border-box',
		position: 'relative',
		zIndex: 5,
		maxHeight: 384,
		minWidth: 128,
		overflow: 'hidden',
		borderRadius: 6,
		borderWidth: 1,
		backgroundColor: white,
		color: `#0F172A`,
		boxShadow: `0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)`,
		animationDuration: '150ms',
		animationTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
		':is([data-state="closed"])': {
			animationName: fadeOutZoomOut,
		},
		':is([data-side="bottom"][data-state="open"])': {
			animationName: slideInFromTopNFadeNZoom(),
		},
		':is([data-side="top"][data-state="open"])': {
			animationName: slideInFromBottomNFadeNZoom(),
		},
		':is([data-side="left"][data-state="open"])': {
			animationName: slideInFromRightNFadeNZoom(),
		},
		':is([data-side="right"][data-state="open"])': {
			animationName: slideInFromLeftNFadeNZoom(),
		},
	},
	selectContentPositionPopper: {
		':is([data-side="bottom"][data-state="open"])': {
			animationName: slideInFromTopNFadeNZoom(4),
			transform: `translateY(4px)`,
		},
		':is([data-side="top"][data-state="open"])': {
			animationName: slideInFromBottomNFadeNZoom(-4),
			transform: `translateY(-4px)`,
		},
		':is([data-side="left"][data-state="open"])': {
			animationName: slideInFromRightNFadeNZoom(-4),
			transform: `translateX(-4px)`,
		},
		':is([data-side="right"][data-state="open"])': {
			animationName: slideInFromLeftNFadeNZoom(4),
			transform: `translateX(4px)`,
		},
	},
	selectViewport: {
		boxSizing: 'border-box',
		padding: 4,
	},
	selectViewportPopper: {
		height: `var(--radix-select-trigger-height)`,
		width: `100%`,
		minWidth: `var(--radix-select-trigger-width)`,
	},
	selectLabel: {
		padding: `6px 8px`,
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		fontWeight: 'bold',
	},
	selectItem: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		cursor: 'default',
		select: 'none',
		borderRadius: 2,
		paddingTop: 6,
		paddingBottom: 6,
		paddingRight: 32,
		paddingLeft: 8,
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		outline: 'none',
		color: darkGrayFontColor,
		':focus': {
			background: brandPrimaryHover,
		},
		':is([data-disabled])': {
			pointerEvents: 'none',
			opacity: 0.5,
		},
	},
	selectSeparator: {
		margin: `4px -4px`,
		height: 1,
		backgroundColor: `$f1f5f9`,
	},
});
