import { StyleSheet } from 'aphrodite';
import { brandSecondary as aidaBrandSecondary, borderColor, white } from '../../../aida/styles/colors';
import { brandPrimary, brandPrimaryDark, brandSecondary, titles } from '../../styles/colors';
import { BreakPoints, BrowserMediaQueries, CssSelectors } from '../../styles/styles';

const loginCredentialsTotalWidth = 414;
const loginCredentialsPadding = 40;
const formFieldHorizontalPadding = 20;
const mobileScreenTotalWIdth = 360;
const mobileCredentialsPadding = 10;

export const styleSheet = StyleSheet.create({
	aidaFormFieldLabel: {
		color: '#858585',
		fontSize: '16px',
	},
	aidaLoginForm: {
		background: white,
		borderRadius: 10,
		marginTop: 50,
		maxWidth: '90vw',
		padding: '50px 70px 60px',
		width: 500,
		[BreakPoints.tabletCompactWidth]: {
			padding: '40px',
		},
		...CssSelectors.allDescendants(
			{
				':focus-within': {
					background: '#fff',
					border: `4px solid ${aidaBrandSecondary} `,
					padding: '15px 20px',
				},
				background: '#f9f9f9',
				border: `1px solid ${borderColor}`,
				marginBottom: 20,
				padding: '18px 23px',
			},
			'.aida-input-field'
		),
	},
	aidaSubmitButton: {
		background: aidaBrandSecondary,
		fontSize: '16px',
		width: '100%',
	},
	cityLeft: {
		bottom: 'calc(30vh - 1px)',
		left: 'calc(50vw - 524px)',
		position: 'absolute',
		[BrowserMediaQueries.IE11]: {
			display: 'none',
		},
	},
	cityRight: {
		bottom: 'calc(30vh - 5px)',
		position: 'absolute',
		right: 'calc(50vw - 552px)',
		[BrowserMediaQueries.IE11]: {
			display: 'none',
		},
	},
	containerBottom: {
		background: 'linear-gradient(180deg, #00416F 1.12%, rgba(0, 62, 89, 0.2) 60.62%)',
		height: '30vh',
		marginTop: '70vh',
		position: 'absolute',
		width: '100vw',
	},
	errorImage: {
		marginLeft: 4,
	},
	flexContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		minHeight: '100vh',
	},
	footer: {
		color: 'white',
		display: 'flex',
		fontSize: 14,
		fontWeight: 600,
		justifyContent: 'space-between',
		marginTop: 8,
	},
	forgotPasswordLink: {
		':active': {
			color: 'white',
		},
		':hover': {
			textDecoration: 'underline',
		},
		':link': {
			color: 'white',
		},
		':visited': {
			color: 'white',
		},
		color: brandSecondary,
		cursor: 'pointer',
		padding: '8px 0',
		textDecoration: 'none',
	},
	formField: {
		':focus-within': {
			background: '#fff',
			border: `${brandSecondary} 4px solid`,
		},
		':not(:first-of-type)': {
			marginTop: 4,
		},
		background: '#fff',
		border: `${brandPrimary} 4px solid`,
		borderRadius: 3,
		boxSizing: 'border-box',
		display: 'flex',
		margin: '0 -4px',
		padding: `15px ${formFieldHorizontalPadding}px`,
		position: 'relative',
	},
	formFieldLabel: {
		':not(:first-of-type)': {
			marginTop: 16,
		},
		color: '#FFFFFF',
		fontSize: 18,
		letterSpacing: 0,
		marginBottom: 8,
		opacity: 0.73,
	},
	formFieldMessage: {
		':not(:first-of-type)': {
			marginTop: 16,
		},
		color: '#FFFFFF',
		fontSize: 14,
		letterSpacing: 0,
		marginBottom: 8,
		opacity: 0.73,
	},
	formFieldPopover: {
		':not(:first-of-type)': {
			marginTop: 50,
		},
	},
	googleMessage: {
		color: '#E8F3F9',
		fontSize: 12,
		marginBottom: 50,
		marginTop: 30,
		textAlign: 'center',
		[BreakPoints.tabletCompactWidth]: {
			width: `${mobileScreenTotalWIdth}px`,
		},
		width: `${1.3 * loginCredentialsTotalWidth}px`,
		zIndex: 2,
	},
	graphicContainer: {
		height: '100vh',
		left: 0,
		overflow: 'hidden',
		position: 'fixed',
		top: 0,
		width: '100vw',
	},
	input: {
		':-ms-input-placeholder': {
			color: '#FFFFFF',
			opacity: 0.73,
		},
		'::placeholder': {
			color: '#FFFFFF',
			opacity: 0.73,
		},
		':focus': {
			'&::placeholder': {
				color: titles,
			},
			background: '#FFFFFF',
			outline: 'none',
		},
		':invalid': {
			boxShadow: 'none',
			outline: 'none',
		},

		':required': {
			boxShadow: 'none',
			outline: 'none',
		},
		background: 'transparent',
		border: 'none',
		boxShadow: 'none',
		color: '#0D0F11',
		flexBasis: 0,
		flexGrow: 1,
		fontSize: 18,
		opacity: 0.73,
		outline: 'none',
	},
	lightningGraphic1: {
		left: 0,
		position: 'absolute',
		top: 0,
	},
	lightningGraphic2: {
		position: 'absolute',
		right: '-60px',
		top: '45vh',
	},
	loader: {
		margin: '50px auto 0px auto',
	},
	login: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		zIndex: 5,
	},
	loginForm: {
		background: '#003A64',
		border: `4px solid ${brandPrimary}`,
		borderRadius: '4px',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 100,
		padding: `28px ${loginCredentialsPadding}px`,
		position: 'relative',
		width: `calc(${loginCredentialsTotalWidth}px - ${2 * loginCredentialsPadding}px)`,
		...CssSelectors.allChildren({
			flexGrow: 1,
		}),
		zIndex: 2,
		[BreakPoints.tabletCompactWidth]: {
			marginTop: 50,
			maxWidth: '80vw',
			padding: '20px 30px',
			width: `calc(${mobileScreenTotalWIdth}px - ${2 * mobileCredentialsPadding}px)`,
		},
	},
	logo: {
		display: 'block',
		height: 62,
		margin: '0 auto',
		marginTop: 50,
		position: 'relative',
		width: 280,
		zIndex: 6,
		[BreakPoints.tabletCompactWidth]: {
			marginTop: 100,
		},
	},
	logoAida: {
		marginTop: -50,
	},
	moonAndStars: {
		position: 'absolute',
		[BrowserMediaQueries.IE11]: {
			display: 'none',
		},
	},
	pluginContainer: {
		background: brandPrimary,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'auto',
	},
	resend: {
		cursor: 'pointer',

		marginLeft: 184,
	},
	signUpButton: {
		':hover': {
			textDecoration: 'underline',
		},
		':visited': {
			color: 'white',
			textDecoration: 'none',
		},
		alignItems: 'center',
		background: 'transparent',
		border: `1px solid ${brandSecondary} `,
		borderRadius: 3,
		color: 'white',
		cursor: 'pointer',
		display: 'flex',
		fontSize: 14,
		height: 40,
		justifyContent: 'center',
		marginTop: 50,
		minWidth: 100,
		padding: '0 20px',
		textDecoration: 'none',
		zIndex: 2,
	},
	standardContainer: {
		background: brandPrimaryDark,
		minHeight: '100%',
		overflowX: 'hidden',
		overflowY: 'auto',
		position: 'relative',
	},
	submitButton: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 3,
		boxShadow: '0 1px 2px 0 rgba(140,140,140,0.50)',
		color: '#fff',
		display: 'flex',
		flexGrow: 1,
		fontSize: 18,
		fontWeight: 600,
		justifyContent: 'center',
		marginTop: 30,
		padding: '15px 0',
	},
});
