import { StyleSheet } from 'aphrodite';
import { brandPrimaryText } from '../../../../styles/colors';

export const s = StyleSheet.create({
	button: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
		fontSize: 12,
		gap: 8,
	},
	container: {
		boxSizing: 'border-box',
	},
	previewContainer: {
		border: 'none',
		boxShadow: 'none',
		height: 400,
		margin: '20px 0',
		padding: 0,
	},
});
