import * as React from 'react';

export function ChevronUp(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={24}
			height={24}
			viewBox='0 0 24 24'
			fill='none'
			stroke='currentColor'
			strokeWidth={2}
			strokeLinecap='round'
			strokeLinejoin='round'
			{...props}
		>
			<path d='m18 15-6-6-6 6' />
		</svg>
	);
}
