import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEmailComposerContext, useRenewalEmailContext } from '../../../../models/Email';
import { ComposeEmailViewModel } from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { ISelectOption, MultiSelect } from '../../DeprecatedSelect';
import { styleSheet } from './styles';

export interface IPolicySelectorProps {
	classifiedPolicies?: Api.IClassifiedPolicies;
	isLoadingClassifiedPolicies?: boolean;
	onSelectedPoliciesChanged?(selectedPolicies: string[]): void;
	selectedPolicies?: string[];
	singleSelectionOnly?: boolean;
	styles?: StyleDeclarationValue[];
}

type PolicySelectorOption = ISelectOption<{
	childIndexes?: number[];
	parentIndex?: number;
	value: string;
}>;

const classifiedLinesOfBusinessToSelectOptions = (
	linesOfBusiness: Api.ClassifiedLinesOfBusiness,
	singleSelectionOnly?: boolean
): PolicySelectorOption[] => {
	if (!linesOfBusiness) {
		return [];
	}
	const categories = Object.keys(linesOfBusiness);
	const result: PolicySelectorOption[] = [];
	categories.forEach(category => {
		const specificLines = linesOfBusiness[category];
		if (specificLines?.length <= 0) {
			return;
		}
		const parent: PolicySelectorOption = {
			dataContext: {
				childIndexes: [],
				value: category,
			},
			id: `category-${category}`,
			text: category,
			type: singleSelectionOnly ? 'icon' : 'checkbox',
		};
		result.push(parent);

		const parentIndex = result.length - 1;
		specificLines?.forEach(x => {
			result.push({
				dataContext: {
					parentIndex,
					value: x.value,
				},
				id: `line-${x.value}`,
				styles: [styleSheet.nestedOption],
				text: x.label,
				type: 'checkbox',
			});
			parent.dataContext.childIndexes?.push(result.length - 1);
		});
	});
	return result;
};

const getSelected = (options: PolicySelectorOption[], selectedPolicies: string[]) => {
	const selected: PolicySelectorOption[] = options.filter(
		x => !x.dataContext.childIndexes && selectedPolicies?.indexOf(x.dataContext.value) >= 0
	);
	const partialSelected: PolicySelectorOption[] = [];

	if (options) {
		const parents = selected.reduce((res, { dataContext }) => {
			res.add(options[dataContext.parentIndex]);
			return res;
		}, new Set<PolicySelectorOption>());
		parents.forEach(parent => {
			if (parent.dataContext.childIndexes?.reduce((res, x) => res && selected.indexOf(options[x]) >= 0, true)) {
				selected.push(parent);
			} else {
				partialSelected.push(parent);
			}
		});
	}

	return [selected, partialSelected] as const;
};

export const PolicySelector = observer(function ({
	classifiedPolicies,
	isLoadingClassifiedPolicies,
	onSelectedPoliciesChanged,
	selectedPolicies,
	singleSelectionOnly,
	styles = [],
}: IPolicySelectorProps) {
	const options = React.useMemo(
		() => classifiedLinesOfBusinessToSelectOptions(classifiedPolicies?.linesOfBusiness, singleSelectionOnly),
		[classifiedPolicies?.linesOfBusiness, singleSelectionOnly]
	);

	const [selectedOptions, partiallySelectedOptions] = React.useMemo(
		() => getSelected(options, selectedPolicies),
		[options, selectedPolicies]
	);

	const onRenderTrigger = () => {
		const totalCount = options.reduce(
			(c, curr) => (curr.dataContext.childIndexes ? curr.dataContext.childIndexes.length : 0) + c,
			0
		);
		const values = new Set(
			selectedOptions
				?.reduce<(string | string[])[]>((res, curr) => {
					if (curr.dataContext.childIndexes?.length) {
						res.push(curr.dataContext.childIndexes.map(x => options[x]).map(x => x.dataContext.value));
					} else {
						res.push(curr.dataContext.value);
					}
					return res;
				}, [])
				.flat()
		);
		const text = values.size === 0 ? null : values.size === totalCount ? 'All' : Array.from(values).join(', ');
		return (
			<>
				<span className={`policy-selector-trigger-label ${css(styleSheet.triggerLabel)}`}>Policy:</span>
				<span className={css(baseStyleSheet.truncateText, styleSheet.triggerText)} title={text ?? undefined}>
					{text || <span className={css(styleSheet.triggerTextNoneSelected)}>None Selected...</span>}
				</span>
			</>
		);
	};

	const onOptionClick = React.useCallback(
		(option: PolicySelectorOption, wasSelected: boolean) => {
			if (singleSelectionOnly && option.type !== 'checkbox') {
				return;
			}

			const nextSelection = new Set<string>([...selectedPolicies]);
			if (wasSelected) {
				if (option.dataContext.childIndexes) {
					option.dataContext.childIndexes.forEach(index => {
						nextSelection.add(options[index].dataContext.value);
					});
				} else {
					if (singleSelectionOnly) {
						nextSelection.clear();
					}
					nextSelection.add(option.dataContext.value);
				}
			} else {
				if (option.dataContext.childIndexes) {
					option.dataContext.childIndexes.forEach(childIndex => {
						const child = options[childIndex];
						nextSelection.delete(child.dataContext.value);
					});
				} else {
					nextSelection.delete(option.dataContext.value);
				}
			}

			const output = Array.from(nextSelection);

			onSelectedPoliciesChanged(output);
			const [selected] = getSelected(options, output);
			return selected;
		},
		[singleSelectionOnly, selectedPolicies, onSelectedPoliciesChanged, options]
	);

	const onRenderOption = (
		option: PolicySelectorOption,
		isSelected: boolean,
		baseStyles: StyleDeclarationValue[],
		asTrigger?: boolean
	) => {
		if (asTrigger) {
			return '';
		}

		return (
			<div title={option.hoverText || option.text} className={css(...baseStyles, styleSheet.option, option.styles)}>
				{singleSelectionOnly && option.type !== 'checkbox' ? (
					<div />
				) : (
					<Checkbox
						checked={isSelected || false}
						className={css(styleSheet.optionCheckbox)}
						id={`policySelector-${option.id}`}
						partial={partiallySelectedOptions?.includes(option)}
						readOnly={true}
					/>
				)}
				<div className={css(baseStyleSheet.truncateText)}>{option.text}</div>
			</div>
		);
	};
	return (
		<MultiSelect
			disabled={!options || options.length === 0 || isLoadingClassifiedPolicies}
			onOptionClick={onOptionClick}
			renderOption={onRenderOption}
			selectAllIfNoneSelected={false}
			selectedOptionsTitle={onRenderTrigger()}
			styles={[styleSheet.select, ...styles]}
			triggerStyles={[styleSheet.trigger]}
			options={options}
			selectedOptions={selectedOptions}
		/>
	);
});

/**
 * Like PolicySelector, but the callbacks and data are linked to EamilComposerContext and RenewalEmailContext
 */
export function RenewalEmailPolicySelector() {
	const composerContext = useEmailComposerContext<Api.IFollowUpOptions, any, ComposeEmailViewModel>();
	const { classifiedPolicies, isFetchingPolicies, onSelectedPoliciesChanged, selectedPolicies } =
		useRenewalEmailContext();

	if (!composerContext?.emailComposer) {
		return null;
	}

	return (
		<PolicySelector
			classifiedPolicies={classifiedPolicies}
			isLoadingClassifiedPolicies={isFetchingPolicies}
			onSelectedPoliciesChanged={onSelectedPoliciesChanged}
			selectedPolicies={selectedPolicies}
		/>
	);
}

/**
 * @see app/models/Email.tsx and RenewalEmailContextProvider for how to use
 * useClassifiedContactPolicies and callbacks around this
 **/
