import { brandSecondary } from '@Styles/colors';
import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

export const CheckmarkIconBold = ({
	fillColor = brandSecondary,
	...props
}: React.SVGProps<SVGSVGElement> & { fillColor?: string }) => {
	return (
		<SvgIcon viewBox='0 0 12 12' width={12} height={12} {...props}>
			<polygon
				fill={fillColor}
				fillRule='evenodd'
				points='5.017 11.974 .116 7.074 2.259 4.931 4.63 7.303 9.65 .274 12.116 2.034'
			/>
		</SvgIcon>
	);
};
