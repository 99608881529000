import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { DayModifiers, Modifier, Modifiers } from 'react-day-picker/types/common';
import { calcScheduleCampaignCalendarDateRange } from '../../../models/UiUtils';
import { useEnvironment } from '../../../models/hooks/appStateHooks';
import { baseStyleSheet as bs } from '../../styles/styles';
import { DayPicker } from '../DayPicker';
import { DeprecatedPopover, PopoverType } from '../DeprecatedPopover';
import { styleSheet } from './styles';

interface IDateRange {
	startDate: Date;
	endDate: Date;
}

export type CalendarModifiers = Partial<Modifiers> & {
	hasCampaign?: Modifier | Modifier[];
	hasCompletedCampaign?: Modifier | Modifier[];
	hasQueuedCampaign?: Modifier | Modifier[];
	hasSend?: Modifier | Modifier[];
};
/** Day picker component that marks campaign days
 * It shows a calendar with the days of the month and highlights the days with completed or queued campaigns.
 */
export function CampaignDayPicker({
	allowPastDates,
	selectedDay,
	onDisableDayClick,
	numWeeksShown,
	disabledDays,
	modifiers,
	onDayClick,
	onDateRangeChange,
	defaultSelectedDate,
	initialMonth,
	onMonthChange,
}: {
	allowPastDates?: boolean;
	selectedDay?: Date;
	onDisableDayClick?: (date: Date) => void;
	numWeeksShown: 4 | 6;
	disabledDays: Modifier[];
	modifiers: CalendarModifiers;
	onDateRangeChange?: (dateRange: IDateRange) => void;
	onDayClick: (day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	defaultSelectedDate?: Date;
	initialMonth?: Date;
	onMonthChange?: (date: Date) => void;
}) {
	const [dayHover, setDayHover] = React.useState<Date | null>(null);
	const environment = useEnvironment();
	const onRenderDay = (day: Date, dateModifiers: Modifiers): React.ReactNode => {
		const styles: StyleDeclarationValue[] = [
			bs.w6,
			bs.h6,
			bs.inlineFlex,
			bs.justifyCenter,
			bs.itemsCenter,
			bs.roundedFull,
			bs.overflowHidden,
		];
		if (dateModifiers.hasCampaign || dateModifiers.hasSend) {
			styles.push(bs.overflowVisible, bs.relative);
		}
		if (dateModifiers.hasQueuedCampaign) {
			styles.push(bs.textBrilliantAzure);
		}
		if (dateModifiers.hasCompletedCampaign) {
			styles.push(bs.textSuccess);
		}
		if (dateModifiers.selected) {
			styles.push(bs.bgBrandPrimary, bs.roundedFull, bs.textWhite);
		}
		if (dateModifiers.hasSend && dateModifiers.selected) {
			styles.push(bs.bgBrilliantAzure);
		}

		if (dateModifiers.disabled) {
			const anchor = (
				<span
					className={css(...styles, bs.pointerEventsAuto, bs.relative)}
					onMouseEnter={() => setDayHover(day)}
					onMouseLeave={() => setDayHover(null)}
					onClick={ev => {
						ev.stopPropagation();
						onDisableDayClick?.(day);
					}}
				>
					{day.getDate()}
				</span>
			);

			return (
				<DeprecatedPopover
					anchor={anchor}
					isOpen={
						moment(dayHover).isSame(day, 'day') &&
						!moment(day).isSameOrBefore(moment(), 'day') &&
						moment(day).isoWeekday() >= 6
					}
					className={css(bs.p2, bs.w80)}
					preferredPlacement='right'
					type={PopoverType.white}
					tipSize={1}
				>
					<p className={css(bs.textGray400, bs.textXs, bs.mAuto, bs.p2)}>
						You cannot schedule sends on the weekend because it&apos;s outside of your business hours. Please email
						<span className={css(bs.brandLink, bs.textXs)}> support@levitateapp.com </span>
						to change your business hours.
					</p>
				</DeprecatedPopover>
			);
		}

		if (dateModifiers?.hasCampaign) {
			return (
				<span className={css(...styles)}>
					{day.getDate()}
					<span className={css(styleSheet.dayPickerDateWithCampaignIndicator)} />
				</span>
			);
		}

		if (dateModifiers?.selected) {
			return <span className={css(...styles)}>{day.getDate()}</span>;
		}
		if (dateModifiers.hasSend) {
			return (
				<span className={css(...styles)}>
					{day.getDate()}
					<span
						className={css(
							bs.bgBlack,
							bs.roundedFull,
							bs.inlineBlock,
							bs.h2,
							bs.w2,
							bs.overflowVisible,
							bs.absolute,
							bs.right0,
							bs.top0
						)}
					/>
				</span>
			);
		}

		return null;
	};
	return (
		<DayPicker
			allowPastDates={allowPastDates}
			defaultSelectedDay={defaultSelectedDate}
			initialMonth={initialMonth}
			className={css(bs.mAuto, bs.flexShrink0)}
			disabledDays={disabledDays}
			environment={environment}
			modifiers={modifiers}
			onDayClick={onDayClick}
			onMonthChange={(date: Date) => {
				onMonthChange?.(date);
				onDateRangeChange?.(calcScheduleCampaignCalendarDateRange({ selectedDate: date, numWeeks: numWeeksShown }));
			}}
			onRenderDay={onRenderDay}
			selectedDays={selectedDay ? [selectedDay] : undefined}
			showOverflowDates={numWeeksShown === 6}
		/>
	);
}
