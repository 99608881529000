import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { IReactComponent, observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { LoginViewModel, UserSessionContext } from '../../../viewmodels/AppViewModels';
import { DeprecatedPopover, PopoverType } from '../../components/DeprecatedPopover';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { CityLeftGraphic } from '../../components/svgs/graphics/CityLeftGraphic';
import { CityRightGraphic } from '../../components/svgs/graphics/CityRightGraphic';
import { MoonAndStarsGraphic } from '../../components/svgs/graphics/MoonAndStarsGraphic';
import { LogoIcon2023 } from '../../components/svgs/icons/LogoIcon2023';
import { baseStyleSheet } from '../../styles/styles';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { ILoginContext, LoginContext } from './context';
import {
	FormFieldErrorMessagePopover,
	IFormFieldErrorMessagePopoverProps,
	ILoginFormFieldProps,
	Login2FAFields,
	LoginEmailField,
	LoginPasswordField,
	LoginPhoneNumberField,
} from './presentation';
import { styleSheet } from './styles';

export interface ILoginProps {
	autoRedirectIfAuthenticated?: boolean;
	className?: string;
	colorScheme?: string;
	loginMode?: 'standard' | 'admin';
	loginVM?: LoginViewModel;
	showRememberMe?: boolean;
	showSignupButton?: boolean;
	userSession?: UserSessionContext;
}

export interface ILoginComponent {
	EmailField: React.FC<ILoginFormFieldProps>;
	FormFieldErrorMessagePopover: React.FC<IFormFieldErrorMessagePopoverProps>;
	PasswordField: React.FC<ILoginFormFieldProps>;
	PhoneNumberField: React.FC<ILoginFormFieldProps>;
	TwoFactorFields: React.FC<ILoginFormFieldProps>;
}

interface IState {
	redirect?: boolean;
	showForgotPasswordDialog?: boolean;
	loginVM?: LoginViewModel;
}

class _Login extends React.Component<
	ILoginProps & RouteComponentProps<any, any, { from?: Location; search?: string }>,
	IState
> {
	private mLoginContext: ILoginContext;
	public static defaultProps: ILoginProps = {
		autoRedirectIfAuthenticated: true,
		loginMode: 'standard',
		showRememberMe: true,
		showSignupButton: true,
	};

	constructor(props: ILoginProps & RouteComponentProps<any, any, { from?: Location; search?: string }>) {
		super(props);
		this.mLoginContext = {
			loginViewModel: props.loginVM || this.getDefaultViewModel(),
		};
		this.state = {
			loginVM: this.mLoginContext.loginViewModel,
			redirect: !!props.autoRedirectIfAuthenticated && !!props.userSession.isAuthenticated,
			showForgotPasswordDialog: false,
		};
	}

	public readonly state: IState = {};

	private getDefaultViewModel() {
		return new LoginViewModel(this.props.userSession, () => this.setState({ redirect: true }));
	}

	@computed
	private get showingTwoFA() {
		const { loginVM } = this.state;
		return loginVM?.needsPhone || loginVM?.challenging;
	}

	public render() {
		const { userSession, showSignupButton } = this.props;
		const { redirect, loginVM, showForgotPasswordDialog } = this.state;

		if (!!redirect && userSession.isAuthenticated) {
			return this.renderRedirect();
		}

		return (
			<LoginContext.Provider value={this.mLoginContext}>
				<div className={css(styleSheet.standardContainer)}>
					<div className={css(styleSheet.flexContainer)}>
						<div>
							{!loginVM.isBusy && <LogoIcon2023 className={css(styleSheet.logo)} />}
							{loginVM?.isBusy ? (
								<LoadingSpinner type='login' className={css(styleSheet.loader)} />
							) : (
								<div className={css(styleSheet.login)}>
									<div className={css(styleSheet.graphicContainer)}>
										<MoonAndStarsGraphic className={css(styleSheet.moonAndStars)} />
										<CityLeftGraphic className={css(styleSheet.cityLeft)} />
										<CityRightGraphic className={css(styleSheet.cityRight)} />
										<div className={css(styleSheet.containerBottom)} />
									</div>
									<div className={css(styleSheet.loginForm)}>
										<Login.FormFieldErrorMessagePopover fieldName='email'>
											<Login.EmailField />
										</Login.FormFieldErrorMessagePopover>
										<Login.FormFieldErrorMessagePopover fieldName='password'>
											<Login.PasswordField onKeyDown={!this.showingTwoFA ? this.onLastFieldKeyDown : undefined} />
										</Login.FormFieldErrorMessagePopover>
										<Login.TwoFactorFields />
										<DeprecatedPopover
											anchor={
												<button className={css(styleSheet.submitButton)} onClick={this.onSubmitSignInForm}>
													{loginVM?.needsPhone ? 'Next' : loginVM?.challenging ? 'Verify' : 'Sign In'}
												</button>
											}
											isOpen={loginVM?.hasErrorMessage('global')}
											type={PopoverType.error}
											contentClassName='login-error-popover'
											preferredPlacement='right'
										>
											<span>{loginVM?.getErrorMessage('global')}</span>
										</DeprecatedPopover>
										<div className={css(styleSheet.footer)}>
											{!loginVM?.challenging && !loginVM?.needsPhone && (
												<span
													onClick={this.onSetForgotPasswordModalOpen(true)}
													className={css(styleSheet.forgotPasswordLink)}
												>
													Forgot Password
												</span>
											)}
										</div>
									</div>
									{!!showSignupButton && (
										<a className={css(styleSheet.signUpButton)} href='https://levitate.ai/#/pricing'>
											Don&apos;t have an account? Sign Up
										</a>
									)}
									<span className={css(styleSheet.googleMessage)}>
										{
											"For GSuite and Gmail Users: Levitate's use of information received from Google APIs will adhere to "
										}
										<a
											className={css(baseStyleSheet.brandSecondaryLink)}
											href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'
											target='_blank'
											rel='noreferrer'
										>
											Google API Services User Data Policy
										</a>
										, including the Limited Use requirements.
									</span>
									<ForgotPasswordModal
										modalProps={{
											isOpen: showForgotPasswordDialog,
											onRequestClose: this.onSetForgotPasswordModalOpen(false),
										}}
										userSession={userSession}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</LoginContext.Provider>
		);
	}

	private renderRedirect() {
		const { location, loginMode } = this.props;
		let pathname = '/dashboard';
		let search: string;
		if (loginMode === 'admin') {
			pathname = `/accounts`;
			if (Api.isProdEnvironment) {
				search = `planId=business-paid`;
			}
		}

		const fromLocation = location.state?.from;
		if (fromLocation) {
			pathname = fromLocation.pathname;
			if (fromLocation.search) {
				search = fromLocation.search;
			}
		}

		return (
			<Redirect
				to={{
					pathname,
					search: search || location.state?.search,
					state: { from: location },
				}}
			/>
		);
	}

	private onSetForgotPasswordModalOpen = (show: boolean) => () => {
		this.setState({ showForgotPasswordDialog: show });
	};

	/** Handle the event where the user hits enter in while editing the password field. */
	private onLastFieldKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.keyCode === 13 || e.key === 'Enter') {
			this.onSubmitSignInForm();
		}
	};

	private onSubmitSignInForm = () => {
		this.state.loginVM.submit();
	};
}

export const Login: Partial<ILoginComponent> & IReactComponent<ILoginProps> = withRouter(observer(_Login));

Login.EmailField = LoginEmailField;
Login.FormFieldErrorMessagePopover = FormFieldErrorMessagePopover;
Login.PasswordField = LoginPasswordField;
Login.PhoneNumberField = LoginPhoneNumberField;
Login.TwoFactorFields = Login2FAFields;
