import { EventLogger, ICredential, IWebServiceHelperCredentialStore, WebServiceHelper } from '@ViewModels';
import { parse as getQueryStringParams } from 'query-string';
import { PersistentStorageManager } from '../models/Storage';
import { BrowserPushNotificationsViewModel } from '../viewmodels/PushNotifications';

/** Simple credential store that honors 'rememberMe' and saves to local storage if needed. */
export class CredentialStore implements IWebServiceHelperCredentialStore {
	private credential: ICredential;
	public getCredential = async () => {
		if (!this.credential) {
			try {
				this.credential = await PersistentStorageManager.local.getObject<ICredential>('credential');
			} catch {
				this.credential = null;
			}
		}
		if (!this.credential && !!window && !!window.location) {
			const comps = (window.location.href || '').split('?');
			const search = comps.length > 1 ? comps[1] : '';
			const qStringParams = getQueryStringParams(search);
			if (qStringParams.newTab === 'true') {
				return new Promise<ICredential>(resolve => {
					let timeoutHandle: any;
					const listener = (storageEvent: StorageEvent) => {
						if (storageEvent.key === 'credential_response' && !!storageEvent.newValue) {
							this.setCredential(JSON.parse(storageEvent.newValue) as ICredential);
							window.removeEventListener('storage', listener, false);
							if (timeoutHandle) {
								clearTimeout(timeoutHandle);
								timeoutHandle = null;
							}
							resolve(this.credential);
						}
					};

					timeoutHandle = setTimeout(() => {
						window.removeEventListener('storage', listener, false);
						if (timeoutHandle) {
							clearTimeout(timeoutHandle);
							timeoutHandle = null;
						}

						resolve(null as ICredential);
					}, 2000);

					window.addEventListener('storage', listener, false);
					PersistentStorageManager.local.setObject('credential_request', 'credential_request').then(() => {
						PersistentStorageManager.local.remove('credential_request');
					});
				});
			}
		}
		return Promise.resolve(this.credential);
	};

	public setCredential = (credential: ICredential) => {
		const credentialWithoutPassword = { ...credential };
		delete credentialWithoutPassword.password;
		this.credential = credentialWithoutPassword;
		return PersistentStorageManager.local.setObject('credential', credentialWithoutPassword);
	};

	public clear = () => {
		const credentials = this.credential;

		this.credential = null;

		const settings = BrowserPushNotificationsViewModel.getlocalSettings();

		PersistentStorageManager.local.clear();
		BrowserPushNotificationsViewModel.setLocalSettings(settings);

		this.clearPushSubscription(credentials);
	};

	private async clearPushSubscription(credentials: ICredential) {
		if (!credentials) {
			return;
		}

		const api = new WebServiceHelper({
			baseUrl: process.env.API_URL,
		});

		const result = await BrowserPushNotificationsViewModel.removeSubscription(api, credentials);
		if (result.success) {
			const settings = BrowserPushNotificationsViewModel.getlocalSettings();

			if (settings && !settings[credentials.user_id]?.inactive) {
				delete settings[credentials.user_id];
				await BrowserPushNotificationsViewModel.setLocalSettingsAsync(settings);
			}
			return;
		}

		if (result.systemCode === 401) {
			// Can happen if tab is left open, auth token expires, and user clicks log out as first action on return to the page.
			return;
		}
		EventLogger.logEvent({ action: 'Unsubscribe-Error', category: 'PushNotifications' }, { ...result });
	}
}
