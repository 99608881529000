import { StyleDeclaration, css } from 'aphrodite';
import * as React from 'react';
import { brandSecondary, grayIconFill } from '../../styles/colors';
import { DeprecatedSelect, ISelectOption } from '../DeprecatedSelect';
import { styleSheet } from './styles';

interface IProps<T = any> {
	animateDots?: boolean;
	styles?: StyleDeclaration[];
	disabled?: boolean;
	options: ISelectOption<T>[];
	onOptionClick?(selectedOption: ISelectOption<T>): void;
}

interface IState {
	isOpen?: boolean;
	isHovering?: boolean;
}

export class MoreMenuDropdown extends React.PureComponent<IProps, IState> {
	public state: IState = {};

	public render() {
		const { onOptionClick, options, styles } = this.props;

		return (
			<DeprecatedSelect
				onOptionClick={onOptionClick}
				openOnHover={true}
				options={options.map(option => ({ ...option, type: 'blue' }))}
				optionStyles={[styleSheet.moreOption]}
				selectedOptionTitle={this.renderTrigger()}
				showCaret={false}
				styles={[styleSheet.menuTriggerContainer, ...(styles || [])]}
				triggerStyles={[styleSheet.menuTrigger]}
			/>
		);
	}

	private renderTrigger() {
		const { animateDots } = this.props;
		const { isHovering } = this.state;

		return (
			<svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' viewBox='0 0 18 4'>
				<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
					<g fill={`${isHovering ? brandSecondary : grayIconFill}`} className='more-menu-trigger-dots'>
						<path
							className={css(styleSheet.dot0, animateDots ? styleSheet.jump : null)}
							d='M4,2 C4,3.106 3.104,4 2,4 C0.895,4 0,3.106 0,2 C0,0.895 0.895,0 2,0 C3.104,0 4,0.895 4,2'
						/>
						<path
							className={css(styleSheet.dot1, animateDots ? styleSheet.jump : null)}
							d='M11,2 C11,3.106 10.104,4 9,4 C7.895,4 7,3.106 7,2 C7,0.895 7.895,0 9,0 C10.104,0 11,0.895 11,2'
						/>
						<path
							className={css(styleSheet.dot2, animateDots ? styleSheet.jump : null)}
							d='M18,2 C18,3.106 17.104,4 16,4 C14.895,4 14,3.106 14,2 C14,0.895 14.895,0 16,0 C17.104,0 18,0.895 18,2'
						/>
					</g>
				</g>
			</svg>
		);
	}
}
