import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useDebounceValue } from '../../../../../hooks/useDebounceValue';
import {
	IImpersonationContextComponentProps,
	IModalContext,
	ImpersonationContextKey,
	InternalLevitateAccountIds,
	ModalChildComponentContextKey,
} from '../../../../../models';
import { useErrorMessages, useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../../../models/Logging';
import { getIndustryName } from '../../../../../models/UiUtils';
import {
	useEmailCategoryTemplates,
	useEmailSearchTemplates,
	useMyTemplatesQuery,
	useTemplateIndustryCategoriesQuery,
} from '../../../../../queries';
import {
	ImpersonationBroker,
	Industry,
	ITemplate,
	ITemplateCard,
	KnownCategories,
	TemplateType,
} from '../../../../../viewmodels/AppViewModels';
import { bs } from '../../../../styles/styles';
import { CampaignCalendarTemplateCard } from '../../../campaigns/CampaignsByCategorySelector/presentation';
import { CampaignSearchInputField } from '../../../campaigns/CampaignSearchInputField';
import { DeprecatedSelect, ISelectOption } from '../../../DeprecatedSelect';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { asModalComponent } from '../../../Modal';
import { styleSheet } from './styles';

interface Props extends IImpersonationContextComponentProps, IModalContext<ITemplate> {
	title: React.ReactNode;
}

function _EmailTemplateBrowser(props: Props) {
	const { impersonationContext } = props;
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const logger = useEventLogging('CampaignSearch');
	const account = impersonationContext?.isValid ? impersonationContext.account : userSession?.account;
	const [industry, setIndustry] = React.useState(account?.additionalInfo?.industry ?? Industry.Insurance);
	const isMyTemplatesDisabled =
		impersonationContext?.isValid && !impersonationContext?.account?.preferences?.csmViewMyCampaignsEnabled;
	const [selectedCategory, setSelectedCategory] = React.useState(
		!isMyTemplatesDisabled ? KnownCategories.MyTemplates : ''
	);
	const [searchQuery, setSearchQuery] = React.useState('');
	const debouncedSearchQuery = useDebounceValue(searchQuery);
	const industryOptions: ISelectOption<Industry>[] = React.useMemo(() => {
		let industries = [
			Industry.Insurance,
			Industry.RealEstate,
			Industry.Financial,
			Industry.Mortgage,
			Industry.Legal,
			Industry.Accounting,
			Industry.NonProfit,
			Industry.HomeMaintenance,
			Industry.Others,
		];
		industries = InternalLevitateAccountIds.has(impersonationContext?.account?.id ?? userSession.account.id)
			? [...industries, Industry.Levitate]
			: industries;
		return industries.map(i => {
			const name = getIndustryName(i);
			return { dataContext: i, id: `industry-option-${name}`, text: name };
		});
	}, [impersonationContext?.account?.id, userSession.account.id]);
	const templateIndustryCategoriesQuery = useTemplateIndustryCategoriesQuery({
		industry,
		impersonationContext,
	});
	const emailSearchTemplatesQuery = useEmailSearchTemplates({
		industry,
		query: debouncedSearchQuery,
		impersonationContext,
		enabled: Boolean(debouncedSearchQuery),
	});
	const myTemplatesQuery = useMyTemplatesQuery<ITemplateCard>({
		enabled: selectedCategory === KnownCategories.MyTemplates,
		industry,
		impersonationContext,
		templateType: TemplateType.Email,
	});
	const categoryTemplatesQuery = useEmailCategoryTemplates({
		enabled: Boolean(selectedCategory) && selectedCategory !== KnownCategories.MyTemplates,
		industry,
		impersonationContext,
		categoryName: selectedCategory,
	});
	const categoryOptions = React.useMemo(() => {
		if (templateIndustryCategoriesQuery.data) {
			return templateIndustryCategoriesQuery.data
				.filter(c => {
					if (isMyTemplatesDisabled) {
						return c !== KnownCategories.MyTemplates;
					}
					return true;
				})
				.map(c => ({
					dataContext: c,
					id: `campaign-search-category-option-${c}`,
					text: c,
				})) as ISelectOption<string>[];
		}
		return [];
	}, [templateIndustryCategoriesQuery.data, isMyTemplatesDisabled]);
	const onIndustryClicked = (option: ISelectOption<Industry>) => {
		setIndustry(option.dataContext);
		setSelectedCategory(!isMyTemplatesDisabled ? KnownCategories.MyTemplates : KnownCategories.Featured);
	};
	const onCategoryClicked = (option: ISelectOption<string>) => {
		setSelectedCategory(option.dataContext);
	};
	const onCampaignTemplateCardClicked = async (card: ITemplateCard, socialIndicator?: boolean) => {
		const templateId = socialIndicator
			? card.associatedTemplates?.find(x => x.templateType === TemplateType.SocialMediaPost)?.id
			: card.id;
		if (templateId) {
			try {
				const template = await userSession.webServiceHelper.callAsync<ITemplate>(
					ImpersonationBroker.composeApiUrl({
						impersonationContext,
						urlPath: `template/${encodeURIComponent(templateId)}`,
						queryParams: {
							expand: 'LastUsedByDate',
						},
					}),
					'GET'
				);
				props.parentModal?.onRequestClose(template, !template);
			} catch (error) {
				errorMessages.pushApiError(error);
				logger.logApiError('TemplateLoad-Error', error);
			}
		}
	};
	const onCampaignSearch = (query: string) => {
		setSearchQuery(query);
	};
	let isLoading = false;
	if (debouncedSearchQuery && !emailSearchTemplatesQuery.isSuccess) {
		isLoading = true;
	} else if (selectedCategory === KnownCategories.MyTemplates && !myTemplatesQuery.isSuccess) {
		isLoading = true;
	} else if (selectedCategory !== KnownCategories.MyTemplates && !categoryTemplatesQuery.isSuccess) {
		isLoading = true;
	}
	const templates = React.useMemo(() => {
		let value: ITemplateCard[] = [];
		if (debouncedSearchQuery && emailSearchTemplatesQuery.data) {
			value = emailSearchTemplatesQuery.data;
		} else if (selectedCategory === KnownCategories.MyTemplates && myTemplatesQuery.data) {
			value = myTemplatesQuery.data;
		} else if (selectedCategory !== KnownCategories.MyTemplates && categoryTemplatesQuery.data) {
			value = categoryTemplatesQuery.data;
		}
		return value;
	}, [
		emailSearchTemplatesQuery.data,
		myTemplatesQuery.data,
		categoryTemplatesQuery.data,
		debouncedSearchQuery,
		selectedCategory,
	]);
	return (
		<div className={`${css(styleSheet.container)}`}>
			<div className={css(styleSheet.header)}>
				{props.title || 'Create a new message template or pick from an existing one...'}
			</div>
			<div className={css(styleSheet.searchContainer)}>
				<CampaignSearchInputField onChange={onCampaignSearch} industry={industry} />
			</div>
			<DeprecatedSelect
				onOptionClick={onIndustryClicked}
				options={industryOptions}
				selectedOptionTitle={<span className={css(styleSheet.triggerText)}>{industry}</span>}
				styles={[styleSheet.select]}
				triggerStyles={[styleSheet.trigger]}
			/>
			{!debouncedSearchQuery ? (
				<DeprecatedSelect
					onOptionClick={onCategoryClicked}
					options={categoryOptions}
					selectedOptionTitle={<span className={css(styleSheet.triggerText)}>{selectedCategory}</span>}
					styles={[styleSheet.select]}
					triggerStyles={[styleSheet.trigger]}
				/>
			) : null}
			{isLoading ? <LoadingSpinner className={css(bs.absoluteCenter)} type='large' /> : null}
			{!isLoading ? (
				<div className={css(styleSheet.cardsContainer)}>
					{templates.map(templateCard => (
						<CampaignCalendarTemplateCard
							card={templateCard}
							className={css(styleSheet.templateCardContainer)}
							key={templateCard.id}
							impersonationContext={impersonationContext}
							isAccountTemplate={selectedCategory === KnownCategories.MyTemplates}
							onCtaClicked={() => onCampaignTemplateCardClicked(templateCard)}
							styles={[styleSheet.templateCard]}
						/>
					))}
				</div>
			) : null}
		</div>
	);
}

const EmailTemplateBrowserAsObserver = observer(_EmailTemplateBrowser);
export const EmailTemplateBrowser = inject(
	ModalChildComponentContextKey,
	ImpersonationContextKey
)(EmailTemplateBrowserAsObserver);

export const EmailTemplateBrowserDialog = asModalComponent(EmailTemplateBrowser, {
	className: css(styleSheet.modal),
	shouldCloseOnOverlayClick: false,
});
